import App from 'next/app';
import Head from 'next/head';
import React from 'react';
import withApolloClient from '../lib/with-apollo-client';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';

// import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import NProgress from 'nprogress';
import Router from 'next/router';
import './empty.css'; // required workaround to support external css
import * as Sentry from '@sentry/browser';
import AWS_AppSync from '../AppSync.json';

Router.onRouteChangeStart = () => NProgress.start();
Router.onRouteChangeComplete = () => NProgress.done();
Router.onRouteChangeError = () => NProgress.done();

Sentry.init({
  dsn: 'https://86d61276b5984f80ade54f6e18ffe1f0@sentry.io/1435912',
  environment: AWS_AppSync.environment || 'dev',
  ignoreErrors: ['Received status code 401', 'Not Authorized to access'],
});

// TODO: remove ApolloHooksProvider when the Apollo community formalizes on Hooks
// https://github.com/apollographql/react-apollo/issues/2539
class MyApp extends App {
  render() {
    const { Component, pageProps, client, query } = this.props;
    const newProps = { ...pageProps, query, client, apolloClient: client };
    return (
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <Head>
            <title>Claire Portal</title>
          </Head>
          <Component {...newProps} />
        </ApolloHooksProvider>
      </ApolloProvider>
    );
  }
}

export default withApolloClient(MyApp);
