import { InMemoryCache } from "apollo-boost";
import fetch from "isomorphic-unfetch";
import AWS_AppSync from "../AppSync";
import Amplify, { Auth } from "aws-amplify";

import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import ApolloClient from "apollo-client";
import { ComplexObjectLink } from "../apollo-links/complex-objects-link";

// import { InMemoryCache } from 'apollo-cache-inmemory';

let apolloClient = null;

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch;
}

//https://github.com/awslabs/aws-mobile-appsync-sdk-js#using-authorization-and-subscription-links-with-apollo-client-no-offline-support
function create(initialState) {
  const url = AWS_AppSync.graphqlEndpoint;
  const region = AWS_AppSync.region;
  // https://aws-amplify.github.io/docs/js/api#manual-configuration
  const auth = {
    type: "API_KEY",
    apiKey: AWS_AppSync.apiKey,

    // Amazon Cognito Federated Identities using AWS Amplify
    // credentials: () => Auth.currentCredentials(),

    // Amazon Cognito user pools using AWS Amplify
    type: "AMAZON_COGNITO_USER_POOLS",
    jwtToken: async () => {
      try {
        const result = await Amplify.Auth.currentSession();
        return result.getIdToken().getJwtToken();
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  };

  const httpLink = createHttpLink({ uri: url });

  const link = ApolloLink.from([
    new ComplexObjectLink(() => Auth.currentCredentials()),
    createAuthLink({
      url,
      region,
      auth
    }),
    createSubscriptionHandshakeLink(url, httpLink)
  ]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    ssrMode: false
  });
  return client;
}

export default function initApollo(initialState) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState);
  }

  return apolloClient;
}
