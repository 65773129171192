import initApollo from './init-apollo'
import Head from 'next/head'
// import { getDataFromTree } from 'react-apollo'
import urlUtils from './url-utils'


export default (App) => {
  return class Apollo extends React.Component {
    static displayName = 'withApollo(App)'

    constructor(props) {
      super(props)
      this.apolloClient = initApollo({});
    }

    render() {
      const query = urlUtils.parseQueryString();
      return <App {...this.props} query={query} client={this.apolloClient} apolloClient={this.apolloClient} />
    }
  }
}
